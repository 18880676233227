import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { Box, Stack } from "./styled";
import { ArrowUpOutlined } from "@ant-design/icons";
import { BsChevronDoubleLeft } from "react-icons/bs";

const CustomBox = styled(Box)`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  margin-top: 0px;
  justify-content: center;
  font-family: "Roboto";
  border-bottom: 1px solid;
  &:hover {
    background: #e7b25e;
    color: white;
    font-weight: 500;
    border-bottom: 1px solid black;
  }
`;

const CustomBoxSelected = styled(Box)`
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  margin-top: 0px;
  justify-content: center;
  font-family: "Roboto";
  border-bottom: 1px solid black;
  background: #e7b25e;
  color: white;
`;

const CustomParagraph = styled("p")`
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    font-weight: 500;
  }
`;

const CustomBoxBack = styled(Box)`
  padding: 5px;
  &:hover {
    color: white;
    background: #d1c7bb;
    border-bottom: 1px solid black;
  }
`;

const FolderNav = (props) => {
  const {
    livello2,
    node3,
    node4,
    handleFolderNav,
    livello3,
    livello6,
    node5,
    handleBackFolderNav,
    depth,
  } = props;
  const onClickFolderNav = (id) => {
    handleFolderNav(id);
  };
  const onClickBackFolderNav = (id) => {
    handleBackFolderNav(id);
  };

  return (
    <>
      {/* {node5 === null || node5 === undefined
        ? FolderNavComp(livello2, node3, node4, onClickFolderNav, depth,onClickBackFolderNav)
        : FolderNavComp(livello3,node4, node5, onClickFolderNav, depth,onClickBackFolderNav)} */}
      {parseInt(depth) === 5
        ? FolderNavComp(
            livello3,
            node4,
            node5,
            onClickFolderNav,
            depth,
            onClickBackFolderNav
          )
        : parseInt(depth) >= 6
        ? FolderNavComp(
            livello6,
            node4,
            node5,
            onClickFolderNav,
            depth,
            onClickBackFolderNav
          )
        : parseInt(depth) === 4
        ? FolderNavComp(
            livello2,
            node3,
            node4,
            onClickFolderNav,
            depth,
            onClickBackFolderNav
          )
        : ""}
    </>
  );
};

function FolderNavComp(
  livello,
  node1,
  node2,
  onClickFolderNav,
  depth,
  action = null
) {
  return (
    <>
      {depth > 4 ? (
        <Row>
          <Col span={8}>
            <Box
              borderBottom="1px solid"
              borderRight="1px solid"
              minHeight="70px"
              background="grigiochiaro"
              pt={["10px", "15px"]}
            >
              <Stack align="center" border="0px solid" justify="center">
                <ArrowUpOutlined style={{ fontSize: "30px" }} />
              </Stack>
            </Box>
          </Col>
          <Col span={16}>
            <CustomBoxBack
              onClick={() => action(node1)}
              borderBottom="1px solid"
              minHeight="70px"
            >
              <CustomParagraph>TORNA A</CustomParagraph>
              <CustomParagraph>
                {livello?.filter((item) => item.key === node1)[0]?.title
                  .length > 35
                  ? livello
                      ?.filter((item) => item.key === node1)[0]
                      ?.title.substring(0, 35) + "..."
                  : livello?.filter((item) => item.key === node1)[0]?.title}
              </CustomParagraph>
              {/* <Stack align="center" justify="center">
              <div style={{width:'100px', border:'1px solid'}}><CustomParagraph>TORNA A</CustomParagraph></div>
              <div style={{width:'100px', border:'1px solid'}}><CustomParagraph>
                  {livello?.filter((item) => item.key === node1)[0]?.title
                    .length > 30
                    ? livello?.filter((item) => item.key === node1)[0]?.title.substring(0, 30) + "..."
                    : livello?.filter((item) => item.key === node1)[0]?.title}
                </CustomParagraph></div> 
              </Stack> */}
            </CustomBoxBack>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Stack
          direction="column"
          align="start"
          flex="1 1 auto"
          border="0px solid"
        >
          {livello
            ?.filter((item) => item.key === node1)[0]
            ?.children?.map((item) =>
              item.key === node2 ? (
                <CustomBoxSelected key={item.key} minHeight="60px" width="100%">
                  <span>{item.title}</span>
                </CustomBoxSelected>
              ) : (
                <CustomBox
                  onClick={() => onClickFolderNav(item.key)}
                  key={item.key}
                  minHeight="60px"
                  width="100%"
                >
                  <span>{item.title}</span>
                </CustomBox>
              )
            )}
        </Stack>
      </Row>
    </>
  );
}

export default FolderNav;
