import React, { useState, useEffect } from "react";
import { Result } from "antd";
import styled from "styled-components";
import { Box } from "./styled";
import { marginTop } from "styled-system";

const CustomBox = styled(Box)`
  width: 80%;
  height: 100%;
  border: 0px solid red;
  margin: 0 auto;
  margin-top: 30px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const CustomImg = styled("img")`
  border: 0px solid red;
  margin: 0 auto;
  display: block;
  height: auto;
  width: 100%;
`;

const Preview = (props) => {
  const { documento, metadati } = props;
  const [path, setPath] = useState("");
  const [srcthumb, setSrcthumb] = useState("");
  const [videosrc, setVideosrc] = useState(null);
  const [audiosrc, setAudiosrc] = useState(null);
  const [mime, setMime] = useState("");
  const [embedId, setEmbedId] = useState("");

  useEffect(() => {
    setSrcthumb(documento.srcthumb);
    setMime(documento.tipomime);
    let embedId = null;
    for (let key in metadati?.metadati) {
      for (let key2 in metadati.metadati[key]) {
        if (
          metadati.metadati[key].hasOwnProperty(key2) &&
          key2 === "youtube_embedid"
        ) {
          embedId = metadati.metadati[key][key2];
          break;
        }
      }
    }
    if (embedId === "--") embedId = null;
    if (documento.srcthumb !== null) {
      setPath(documento.srcthumb);
      setVideosrc(null);
      setAudiosrc(null);
    } else if (embedId !== null) {
      setVideosrc(null);
      setAudiosrc(null);
      setEmbedId(embedId);
    } else if (documento.videosrc !== null) {
      setPath(documento.path);
      setVideosrc(1);
    } else if (documento.audiosrc !== null) {
      setPath(documento.path);
      setVideosrc(null);
      setAudiosrc(1);
    } else {
      setPath(documento.path);
      setVideosrc(null);
      setAudiosrc(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documento]);
  return (
    <>
      {srcthumb !== null ? (
        <CustomBox>
          <CustomImg key={documento.id} src={path} />
        </CustomBox>
      ) : videosrc === 1 ? (
        <CustomBox>
          <video
            width="100%"
            height="100%"
            controls
            key={documento.id}
            style={{ display: "flex", margin: "0 auto" }}
          >
            <source src={path} type={mime} />
          </video>
        </CustomBox>
      ) : embedId !== "" && embedId !== null ? (
        <CustomBox>
          <iframe
            width="100%"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </CustomBox>
      ) : audiosrc === 1 ? (
        <CustomBox style={{ marginTop: "150px" }}>
          <audio
            key={documento.id}
            controls
            style={{ display: "flex", margin: "0 auto" }}
          >
            <source src={path} type={mime} />
            Il tuo browser non supporta elementi audio.
          </audio>
        </CustomBox>
      ) : (
        <Result
          key={documento.id}
          status="404"
          title="404"
          subTitle="Ci scusiamo. Nessuna preview del documento!"
        />
      )}
    </>
  );
};

export default Preview;
