import { createSlice } from "@reduxjs/toolkit";
import { api2 } from "../api";

// initial state
export const initialState = {
  loading_ricerca: false,
  total:null,
  page:1,
  start:0,
  end: 0,
  error: {
    status: false,
    message: "",
    code: "",
  },
  risultato: [],
};

// our slice
const ricercaSlice = createSlice({
  name: "ricerca",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading_ricerca = true;
    },

    setRisultato: (state, { payload }) => {
      state.loading_ricerca = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.total=payload.total;
      state.start=payload.start;
      state.end=payload.end;
      state.risultato = payload.data;
    },
    resetRicerca: (state) => {
      state.total=null;
      state.start=0;
      state.end=0;
      state.page=1;
      state.risultato = [];
      state.loading_ricerca= false;

    },

    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setStart: (state, { payload }) => {
      state.start = payload;
    },
    setEnd: (state, { payload }) => {
      state.end = payload;
    },

    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading_ricerca = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
  },
});

// export the actions
export const { cleanError, setLoading, setError, setRisultato,setEnd,setPage,setStart,setTotal,resetRicerca } =
ricercaSlice.actions;

// export the default reducer
export default ricercaSlice.reducer;

export const fetchRicerca = (data,page) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "api.php?f=ricerca&page="+page;
  try {
    const response = await api2.post(path,data, {
      headers: {
        "Content-type": "application/json",
      },
    });
    dispatch(setRisultato(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};
