import { createSlice } from "@reduxjs/toolkit";
import { api,api2 } from "../api";

// initial state
export const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  modelli: [],
  contenitori: [],
};

// our slice
const modelliSlice = createSlice({
  name: "modelli",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setModelli: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.modelli = payload;
    },
    setContenitori: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.contenitori = payload;
    },
    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
  },
});

// export the actions
export const { setLoading, setModelli,setContenitori, setError, cleanError, deleteData } =
  modelliSlice.actions;

// export the default reducer
export default modelliSlice.reducer;


export const fetchContenitori = () => async (dispatch) => {
  //dispatch(setLoading());
  dispatch(cleanError());
  let path = "api.php?f=contenitori";
  try {
    const response = await api2.post(path, {
      headers: {
        "Content-type": "application/json",
      },
    });
    dispatch(setContenitori(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};


export const fetchModelli= () => async (dispatch) => {
 // dispatch(setLoading());
  dispatch(cleanError());
  let path = "api.php?f=modelli";
  try {
    const response = await api2.post(path, {
      headers: {
        "Content-type": "application/json",
      },
    });
    dispatch(setModelli(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};



var xml = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:mod="modelsList">
  <soapenv:Header/>
  <soapenv:Body>
     <mod:modelsList soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
        <username xsi:type="xsd:string">admin</username>
        <password xsi:type="xsd:string">sppsm04</password>
        <codice_azienda xsi:type="xsd:string">spicgil</codice_azienda>
     </mod:modelsList>
  </soapenv:Body>
</soapenv:Envelope>
`;


export const fetchModelli_old = () => async (dispatch) => {
  //dispatch(setLoading());
  dispatch(cleanError());
  try {
    const response = await api.post("", xml, {
      headers: { "Content-Type": "text/xml;charset=UTF-8" },
    });

    var xmldata = parseXml(response.data);
    var data = parseXml(xmldata["SOAP-ENV:Envelope"]["SOAP-ENV:Body"]["ns1:modelsListResponse"]["risultato"]["__text"]);
    var models = data["response"]["models"]["model"];
    dispatch(setModelli(models));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

function parseXml(xml, arrayTags) {
  let dom = null;
  if (window.DOMParser) dom = new DOMParser().parseFromString(xml, "text/xml");
  function parseNode(xmlNode, result) {
    if (xmlNode.nodeName == "#text" || xmlNode.nodeName == "#cdata-section") {
      let v = xmlNode.nodeValue;
      if (v.trim()) result["__text"] = v;
      return;
    }

    let jsonNode = {},
      existing = result[xmlNode.nodeName];
    if (existing) {
      if (!Array.isArray(existing))
        result[xmlNode.nodeName] = [existing, jsonNode];
      else result[xmlNode.nodeName].push(jsonNode);
    } else {
      if (arrayTags && arrayTags.indexOf(xmlNode.nodeName) != -1)
        result[xmlNode.nodeName] = [jsonNode];
      else result[xmlNode.nodeName] = jsonNode;
    }

    if (xmlNode.attributes)
      for (let attribute of xmlNode.attributes)
        jsonNode[attribute.nodeName] = attribute.nodeValue;

    for (let node of xmlNode.childNodes) parseNode(node, jsonNode);
  }

  let result = {};
  for (let node of dom.childNodes) parseNode(node, result);

  return result;
}
