import axios from "axios";
import { config } from '../Constants'


export const api = axios.create({
  baseURL: config.url.BASE_URL_WSDL,
});


export const api2 = axios.create({
  baseURL: config.url.BASE_URL_REST,
});

// export const getItemFromLocalStorage = (key) => {
//   if (localStorage.getItem(key)) {
//     return JSON.parse(localStorage.getItem(key));
//   }
//   return null;
// };

// export const setLocalStorageItem = (key, item) => {
//   if (item && typeof key === "string") {
//     localStorage.setItem(key, JSON.stringify(item));
//   }
// };

export const removeLocalStorageItem = (key) => {
    if (typeof key === "string") {
      localStorage.removeItem(key);
    }
  };


// Salva un valore nel LocalStorage con una data di scadenza

//25h in millisecondi = 24 * 60 * 60 * 1000 = 86400000
export const setLocalStorageItem = (key, value, ttl=86400000) => {
  const now = new Date()
  const expiry = now.getTime() + ttl //ttl in millisecondi
  localStorage.setItem(key, JSON.stringify({ value, expiry }))
}

// Recupera un valore dal LocalStorage e verifica se è scaduto
export const getItemFromLocalStorage = (key) => {
  const item = JSON.parse(localStorage.getItem(key))
  if (!item) {
    return null
  }

  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}  
  

localStorage.removeItem('image');
