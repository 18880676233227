import React from "react";
import { Result} from "antd";
import Layout from "../components/Layout";
import { Container } from "../components/styled";
const NotFound = () => {
  return (
    <Layout>
      <Container
        size="fullwidth"
        style={{ marginBottom: "55px", minHeight: "500px" }}
      >
        <Result
          status="404"
          title="404"
          style={{ marginTop: "70px" }}
          subTitle="Ci scusiamo. La pagina visitata non esiste."
        />
      </Container>
    </Layout>
  );
};

export default NotFound;
