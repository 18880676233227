import { createSlice } from "@reduxjs/toolkit";
import { api2 } from "../api";

// initial state
export const initialState = {
  loading_doc: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  metadati: [],
  documento: []
};

// our slice
const docSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading_doc = true;
    },

    setMetadati: (state, { payload }) => {
      state.loading_doc = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.metadati = payload;
    },
    setDocumento: (state, { payload }) => {
      state.loading_doc = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.documento = payload;
    },
  
    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading_doc = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
  },
});

// export the actions
export const {
  cleanError,
  setLoading,
  setError,
  setMetadati,setDocumento 
} = docSlice.actions;

// export the default reducer
export default docSlice.reducer;

export const fetchMetadati =
  (docid) =>
  async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    let path = "api.php?f=getMetadati&docID=" + docid;
    try {
      const response = await api2.get(path, {
        headers: {
          "Content-type": "application/json",
        },
      });
      
     dispatch(setMetadati(response.data));
     
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

  export const fetchDocumento =
  (docid) =>
  async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    let path = "api.php?f=getDoc&docID=" + docid;
    try {
      const response = await api2.get(path, {
        headers: {
          "Content-type": "application/json",
        },
      });
      
     dispatch(setDocumento(response.data));
     
    } catch (err) {
      dispatch(setError(err.response));
    }
  };  
