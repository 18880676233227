import React from "react";
import styled from "styled-components";
import { Box } from "./styled";

const CustomBox = styled(Box)`
  width: 195px;
  height: 60px;
  border-left: 10px solid #e7b25e;
  background-color:  ${props => props.selected ? '#e7b25e' : 'white'};
  color:  ${props => props.selected ? 'white' : 'black'};
  margin: 20px;
  margin-left: 0px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight:  ${props => props.selected ? 500 : 600};

  cursor: pointer;
  &:hover {
    color: white;
    background-color: #e7b25e;
    font-weight: 500;
  }
`;

const CustomParagraph = styled("p")`
  padding: 5px;
  margin-left: 5px;
`;

const Folder = (props) => {
  //console.log(props);
  const { title, id, depth, handleFolder,selected } = props;
  const onClickFolder = () => {
    handleFolder(id);
  };

  return (
    <CustomBox key={id} selected={selected} depth={depth} onClick={onClickFolder}>
      <CustomParagraph>
        {title.length > 45 ? title.substring(0, 45) + "..." : title}
      </CustomParagraph>
    </CustomBox>
  );
};

export default Folder;
