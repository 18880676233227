import { createSlice } from "@reduxjs/toolkit";
import { api2 } from "../api";

// initial state
export const initialState = {
  loading_meta: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  metadati: [],
};

// our slice
const metadatiSlice = createSlice({
  name: "metadati",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading_meta = true;
    },

    setMetadati: (state, { payload }) => {
      state.loading_meta = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.metadati = payload;
    },

    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading_meta = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
  },
});

// export the actions
export const { cleanError, setLoading, setError, setMetadati } =
  metadatiSlice.actions;

// export the default reducer
export default metadatiSlice.reducer;

export const fetchMetadati = (id) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "api.php?f=loadMeta&modelloid=" + id;
  try {
    const response = await api2.get(path, {
      headers: {
        "Content-type": "application/json",
      },
    });

    dispatch(setMetadati(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};
