/* eslint-disable no-unreachable */
import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Select,
  Anchor,
} from "antd";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import styled from "styled-components";
import { Box } from "./styled";
import "../css/formRicerca.css";
import moment from "moment";
import "moment/locale/it"; // importa la libreria di localizzazione per italiano
import AnchorLink from "react-anchor-link-smooth-scroll-v2";
const { Option } = Select;

moment.locale("it"); // imposta la lingua corrente su italiano

const { TextArea } = Input;

const CustomBox = styled(Box)`
  display: ${(props) => (props.visibility ? "block" : "none")};
  font-family: "Roboto";
  font-size: 20px;
  background-color: white;
  width: 100%;
  padding: 50px;
  min-height: 400px;
  color: #818386;
  border-bottom: 2px solid #818386;
  border-right: 2px solid #818386;
  border-left: 2px solid #818386;
  border-top: ${(props) => (props.affinaVis ? "" : "2px solid #818386")};
`;

const CustomBoxBtn = styled(Box)`
  display: ${(props) => (props.visibility ? "block" : "none")};
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  color: #818386;
  background-color: white;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #818386;
  border-right: 2px solid #818386;
  border-left: 2px solid #818386;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #818386;
  }
`;

const CustomBoxBtnAffina = styled(Box)`
  display: ${(props) => (props.visibility ? "block" : "none")};
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => (props.formCercaVis ? "#818386" : "white")};
  background-color: ${(props) => (props.formCercaVis ? "white" : "#818386")};
  width: 100%;
  padding: 10px;
  text-align: center;
  border-top: 2px solid #818386;
  border-right: 2px solid #818386;
  border-left: 2px solid #818386;
  border-bottom: ${(props) => (props.formCercaVis ? "" : "2px solid #818386")};
  cursor: pointer;
  /* &:hover {
    color: white;
    background-color: #818386;
  } */
`;

const style = {
  padding: "8px 0",
};

const tipoMeta = {
  1: "TEXT",
  2: "INTEGER",
  3: "FLOAT",
  4: "COUNTER",
  5: "DATE",
  6: "DATETIME",
  7: "TIME",
  8: "LOOKUP",
  9: "AMOUNT",
  10: "EXPIRE",
  11: "EXT_LOOKUP",
  12: "EMAIL",
  14: "TESTO_RICERCA",
  15: "MULTILOOKUP",
  16: "TESTO_INTERVALLO",
  17: "TESTO_CALCOLATO",
};

const FormRicercaFull = (props) => {
  const [metaState, setMetaState] = useState({});
  const [metaLabelState, setMetaLabelState] = useState({});
  const {
    loading_meta,
    metadati,
    handleRicerca,
    affinaVis,
    toggleRicerca,
    formCercaVis,
  } = props;
  const [form] = Form.useForm();

  const onChange = (checkedValues, e) => {
    handleDisabledCheck();
    checkedValues.map((i) => {
      setMetaState((metaState) => ({
        ...metaState, // <-- shallow copy previous state
        [i + "_a"]: false,
      }));
    });

    checkedValues.map((i) => {
      setMetaLabelState((metaLabelState) => ({
        ...metaLabelState, // <-- shallow copy previous state
        [i + "_a"]: "a",
      }));
    });
  };
  const onFinish = (values) => {
    //console.log("Received values of form: ", values);
    handleRicerca(values);
  };
  const handleCerca = () => {
    form.submit();
  };

  const handleDisabledCheck = () => {
    metadati.map((item) =>
      setMetaState((metaState) => ({
        ...metaState, // <-- shallow copy previous state
        [item.colonna_gv + "_a"]: true,
      }))
    );
    metadati.map((item) =>
      setMetaLabelState((metaLabelState) => ({
        ...metaLabelState, // <-- shallow copy previous state
        [item.colonna_gv + "_a"]: " ",
      }))
    );
  };

  const handleAffina = () => {
    toggleRicerca();
  };

  useEffect(() => {
    handleDisabledCheck();
  }, [metadati]);


  return (
    <>
      <CustomBoxBtnAffina
        visibility={affinaVis}
        formCercaVis={formCercaVis}
        onClick={handleAffina}
      >
        AFFINA RICERCA &nbsp;
        {formCercaVis ? (
          <HiChevronUp style={{ fontSize: "20px" }} />
        ) : (
          <HiChevronDown style={{ fontSize: "20px" }} />
        )}
      </CustomBoxBtnAffina>
      <CustomBox visibility={formCercaVis} affinaVis={affinaVis}>
        <Form
          onFinish={onFinish}
          name="form-meta"
          form={form}
          layout="vertical"
        >
          <Row gutter={[16, 24]} style={{ marginBottom: "20px" }}>
            <Col>
              <span
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                * Abilita intervallo
              </span>
            </Col>
          </Row>
          <Checkbox.Group onChange={onChange} style={{ border: "0px solid" }}>
            <Row style={{ border: "0px solid" }}>
              {metadati.map((item) =>
                metaDato(item, metaState, metaLabelState)
              )}
            </Row>
          </Checkbox.Group>
        </Form>
      </CustomBox>
      <AnchorLink offset="300" href="#ref-risultati" style={{textDecoration:'unset'}}>
        <CustomBoxBtn visibility={formCercaVis} onClick={handleCerca}>
          CERCA
        </CustomBoxBtn>
      </AnchorLink>
    </>
  );
};

export default FormRicercaFull;

function metaDato(item, metaState, metaLabelState) {
  const dateFormat = "DD/MM/YYYY";
  // eslint-disable-next-line default-case
  switch (tipoMeta[parseInt(item.tipometadato)]) {
    case "MULTILOOKUP":
      const lookup_multi = [];
      item.valueslk.map((x) => {
        lookup_multi.push(
          <Option value={x.id} key={x.id}>
            {x.descrizione.trim()}
          </Option>
        );
        return lookup_multi;
      });
      return (
        <>
          <Col className="gutter-row-meta" span={12}>
            <div style={style}>
              <Row gutter={[16, 24]}>
                <Col span={20}>
                  <Form.Item name={item.colonna_gv} label={item.label}>
                    <Select
                      mode="multiple"
                      placeholder="Scegli"
                      style={{ width: "370px", marginTop: "10px" }}
                      allowClear
                    >
                      {lookup_multi}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </>
      );
    
    case "LOOKUP":
      const lookup = [];
      item.valueslk.map((x) => {
        // console.log(modello);
        lookup.push(
          <Option value={x.id} key={x.id}>
            {x.descrizione.trim()}
          </Option>
        );
        return lookup;
      });
      return (
        <>
          <Col className="gutter-row-meta" span={12}>
            <div style={style}>
              <Row gutter={[16, 24]}>
                <Col span={20}>
                  <Form.Item name={item.colonna_gv} label={item.label}>
                    <Select
                      placeholder="Scegli"
                      style={{ width: "370px", marginTop: "10px" }}
                      allowClear
                    >
                      {lookup}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </>
      );
 
    case "DATE":
    case "EXPIRE":
      return (
        <>
          <Col className="gutter-row-meta" span={12}>
            <div style={style}>
              <Row gutter={[16, 24]}>
                <Col span={10}>
                  <Form.Item name={item.colonna_gv} label={item.label}>
                    <DatePicker
                      format={dateFormat}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name={item.colonna_gv + "_a"}
                    label={metaLabelState[item.colonna_gv + "_a"]}
                  >
                    {/* <DatePicker  /> */}
                    <DatePicker
                      format={dateFormat}
                      disabled={metaState[item.colonna_gv + "_a"]}
                      style={{ width: "100%" }}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="*">
                    <Checkbox value={item.colonna_gv} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </>
      );
   
    case "TEXT":
    case "TESTO_RICERCA":
    case "EMAIL":
    default:
      return (
        <>
          <Col className="gutter-row-meta" span={12}>
            <div style={style}>
              <Row gutter={[16, 24]}>
                <Col span={10}>
                  <Form.Item name={item.colonna_gv} label={item.label}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name={item.colonna_gv + "_a"}
                    label={metaLabelState[item.colonna_gv + "_a"]}
                  >
                    <Input disabled={metaState[item.colonna_gv + "_a"]} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="*">
                    <Checkbox value={item.colonna_gv} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </>
      );
      // eslint-disable-next-line no-unreachable
   
  }
}
