import React from 'react'
import styled from "styled-components";
import { Box } from './styled';
import { CloseCircleOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";

const CustomBoxOverflow = styled(Box)`
  display: ${(props) => (props.visibility ? "block" : "none")};
  font-family: "Roboto";
  font-size: 20px;
  background:${(props)=> (props.type == 1 ? "#161712" : "#EC1C24" )};
  opacity: 0.9;
  width: 100%;
  color: white;
  height: 100%;
  z-index: 999;
  position: fixed;
  margin: 0px;
  border:0px solid red;
  top:0px;
  display: black;
 // text-align: center;
`;

const BoxOverlayer = (props) => {
  /*type 1 => OK 2 =>KO*/
  const{boxOverLayerVisibility,type,handleToggleLayer}= props;  

  return (
    <>
    <CustomBoxOverflow type={type} visibility={boxOverLayerVisibility}>
        
        <Row gutter={24}>
            <Col  offset={12} >
                <CloseCircleOutlined  style={{fontSize:'18px', cursor:'pointer', marginTop:"100px", paddingLeft:'300px', paddingBottom:'30px',textAlign:'center'}} onClick={handleToggleLayer} />
            </Col>
            <Col span={12} offset={6}>
                {type === 1 
                ? <p style={{fontFamily:'Roboto', fontWeight:700, fontSize:'30px',textAlign:'center'}}><span style={{textDecoration:'underline'}}>Grazie per la tua richiesta.</span><br></br><br></br>Verrai ricontattato al più presto</p> 
                : <p style={{fontFamily:'Roboto', fontWeight:700, fontSize:'30px',textAlign:'center'}}><span style={{textDecoration:'underline'}}> Attenzione!</span><br></br><br></br>Richiesta fallita<br></br>Riprovare</p>}
            </Col>
        </Row>
    </CustomBoxOverflow>
    </>
  )
}

export default BoxOverlayer