import React, {useEffect} from "react";
import Layout from "../components/Layout";
import {Container, Stack,LinkHome } from "../components/styled";
import { Link } from "react-router-dom";
import { Spin } from 'antd'
// redux mapping
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTree,setNode2,setNode3,
  setNode4,
  setNode5,
  setNode6,
  setDepth,
  setLoading
} from "../redux/reducers/tree_reducer";

const Home = () => {
   // set up dispatch
   const dispatch = useDispatch();
   // fetch data dal nostro store redux
   // destrutturiamo lo slice appDetail
   const { livello1,loading,loading1 } = useSelector(
     (state) => state.tree
   );
   useEffect(()=>{
    dispatch(setLoading(true));
    dispatch(setNode2(null));
    dispatch(setNode3(null));
    dispatch(setNode4(null));
    dispatch(setNode5(null));
    dispatch(setNode6(null));
    dispatch(setDepth(null));
    dispatch(fetchTree(1,2))
   },[dispatch])

  const goArchivio = (id) => {
    dispatch(setDepth(2));
    dispatch(setNode2(id));
  }
  
  useEffect(()=>{
    if(loading1 === false){
      setTimeout(function() {
        dispatch(setLoading(false));
      }, 300);
 
    }
  },[dispatch,loading1]);
 
  return (
    <Layout >
      <Spin  size="large" spinning={loading}>
      <Container size='fullwidth' style={{marginBottom:'55px',minHeight:'500px'}}>
        <Container
          size="md"
          mt={["24px", "72px"]}
          style={{ border: "0px solid", display: loading ? "none" : "block" }}
        >
          <Stack
            direction="column"
            align="start"
            spacing="48px"
            flex="1 1 auto"
            border="0px solid"
          >
            {livello1.data?.map((item) => (
              <LinkHome variant="text" size={["lg", "xl"]}>
                <Link
                  onClick={()=>goArchivio(item.key)}
                  to={"archivio/"+item.key}
                  style={{
                    textDecoration: "none",
                    color:"black"
                  }}
                >
                   {item.title}

                </Link>
               
              </LinkHome>
            ))}
          </Stack>
        </Container>
      </Container>
      </Spin>
    </Layout>
  );
};

export default Home;
