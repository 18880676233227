import { api2 } from "./api";

export const Service = {
    sendMail
}


function sendMail(data) {
    let path = "api.php?f=mail";
    return api2.post(path, data, {
        headers: {
            'Content-type': 'application/json',
        }
    })

}
