const prod = {
    url: {
      BASE_URL_WSDL: "https://www.memospi.it/soap/server.php?wsdl",
      BASE_URL_REST: "https://www.memospi.it/portale/ws"
    }
  }
  
  const dev = {
    url: {
      BASE_URL_WSDL: "https://dev.zenshare.it/soap/server.php?wsdl",
      BASE_URL_REST: "https://dev.zenshare.it/portale/ws"
    }
  }
  
  export const config = process.env.NODE_ENV === 'development' ? dev : prod