import {
    space,
    layout,
    border,
    flexbox,
    color,
    compose,
    position,
  } from "styled-system";
  //serve per scrivere direttamnete il css
  import { css } from "@styled-system/css";
  import styled from "styled-components";



  const StackWrapper = styled("div")(
    ({
      childrenMarginTop,
      childrenMarginBottom,
      childrenMarginRight,
      childrenMarginLeft,
      align,
      justify,
      direction,
      flexWrap,
    }) =>//ritorna una funzione che accetta un obj
      css({
        display: "flex",
        alignItems: align,
        justifyContent: justify,
        flexDirection: direction,
        flexWrap: flexWrap,
        "& > * + *": {//selettore che indica: rispetto a questo div tutti ifgli successivi a tutti i figlio (in modo che il primo elemento non subisco gli effetti di seguito)
          marginTop: childrenMarginTop,
          marginBottom: childrenMarginBottom,
          marginLeft: childrenMarginLeft,
          marginRight: childrenMarginRight,
        },
      }),
    compose(flexbox, border, layout, color, space, position)
  );

  //creazione el componente
  
  const Stack = ({
    align,
    justify,
    spacing,
    direction = "row",
    flexWrap ="unset",
    children,
    ...rest
  }) => {
    return (
      <StackWrapper
        {...rest}
        childrenMarginTop={direction === "column" ? spacing : 0}
        childrenMarginBottom={direction === "column-reverse" ? spacing : 0}
        childrenMarginLeft={direction === "row" ? spacing : 0}
        childrenMarginRight={direction === "row-reverse" ? spacing : 0}
        align={align}
        flexWrap={flexWrap}
        justify={justify}
        direction={direction}
      >
        {children}
      </StackWrapper>
    );
  };
  
  export default Stack;