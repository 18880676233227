import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Box } from "./styled";
import FormDoc from "./FormDoc";
import { Space } from "antd";
import SchedaDoc from "./SchedaDoc";
import Preview from "./Preview";

const CustomBoxScheda = styled(Box)`
  display: ${(props) => (props.visibility ? "block" : "none")};
  margin-top: 100px;
`;

const CustomBox = styled(Box)`
  display: ${(props) => (props.visibility ? "block" : "none")};
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  color: white;
  background-color: #ec1c24;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #ec1c24;
  border-right: 2px solid #ec1c24;
  border-left: 2px solid #ec1c24;
  cursor: pointer;
  &:hover {
    color: #ec1c24;
    background-color: white;
  }
`;

const STabs = styled(Tabs)`
  font-family: "Roboto";
  font-size: 12px;
  color: #ec1c24;
  width: 100%;
 
`;

const STabList = styled(TabList)`
  list-style-type: none;
  padding: 4px;
  padding-left: 0px;
  display: flex;
  margin: 0;
`;
STabList.tabsRole = "TabList";

const STab = styled(Tab)`
  margin-right: 4px;
  border: 2px solid #ec1c24;
  border-bottom: 0px;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  user-select: none;
  cursor: pointer;

  &.is-selected {
    color: white;
    background: #ec1c24;
    border-bottom: 1px solid #ec1c24;
  }

  &:focus {
    outline: none;
    //box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
  }
`;
STab.tabsRole = "Tab";

const STabPanel = styled(TabPanel)`
  display: none;
  min-height: 40vh;
  border: 2px solid #ec1c24;
  padding: 4px;
  margin-top: -5px;
  background: white;
  &.is-selected {
    display: block;
  }
`;
STabPanel.tabsRole = "TabPanel";

const ContentTabsDoc = (props) => {
  const {
    handleToggleLayer,
    handleType,
    visibilityScheda,
    loading_doc,
    metadati,
    documento,
  } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [visForm, setVisForm] = useState(false);
  const handleTabs = (index) => {
    setTabIndex(index);
  };
  const handleGetDoc = () => {
    setVisForm(!visForm);
  };
  const handleCloseForm = () => {
    setVisForm(!visForm);
  };
  let embedId = null;
  for (let key in metadati?.metadati) {
    for (let key2 in metadati.metadati[key]) {
      if (metadati.metadati[key].hasOwnProperty(key2) && key2 === "youtube_embedid") {
        embedId = metadati.metadati[key][key2];
        break;
      }
    }
  }
  if(embedId ==='--') embedId =null;
  return (
    <><section id='ref-schedadoc' style={{padding:'20px',marginBottom:'170px'}}  ref={elem => {
      // Verificare che l'elemento esista prima di chiamare scrollIntoView()
      if (elem && visibilityScheda) {
        elem.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'nearest'});
      }
    }}>  </section>
    <CustomBoxScheda visibility={visibilityScheda}>
      <STabs
        selectedTabClassName="is-selected"
        selectedTabPanelClassName="is-selected"
        selectedIndex={tabIndex}
        onSelect={(index) => handleTabs(index)}
      >
        <STabList>
          <STab key={1}>SCHEDA DOCUMENTO</STab>
          <STab key={2}>{documento?.tipomime?.startsWith('video/') || embedId !==null ? "VIDEO" : documento?.tipomime?.startsWith('audio/')? "AUDIO": "PREVIEW"}</STab>
        </STabList>
        <STabPanel>
          <SchedaDoc loading_doc={loading_doc} metadati={metadati}></SchedaDoc>
        </STabPanel>
        <STabPanel><Preview loading_doc={loading_doc} documento={documento} metadati={metadati}/></STabPanel>
      </STabs>
      <CustomBox visibility={!visForm} onClick={handleGetDoc}>
        RICHIEDI DOCUMENTO
      </CustomBox>
      <FormDoc
        visForm={visForm}
        documento={documento}
        handleToggleLayer={handleToggleLayer}
        handleType={handleType}
        handleCloseForm={handleCloseForm}
      ></FormDoc>
      <Box height={["64px", "150px"]} width="100%"></Box>
    </CustomBoxScheda>
  
    </>
  );
};

export default ContentTabsDoc;
