import React from "react";
import { Form, Input, Row, Col } from "antd";
import styled from "styled-components";
import { Box } from "./styled";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Service } from "../redux/service";
import "../css/archivio.css";
const { TextArea } = Input;
const CustomBox = styled(Box)`
  display: ${(props) => (props.visibility ? "block" : "none")};
  font-family: "Roboto";
  font-size: 20px;
  background-color: white;
  width: 100%;
  padding: 50px;
  color: #818386;
  border-bottom: 2px solid #ec1c24;
  border-right: 2px solid #ec1c24;
  border-left: 2px solid #ec1c24;
`;

const CustomBoxBtn = styled(Box)`
  display: ${(props) => (props.visibility ? "block" : "none")};
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  color: white;
  background-color: #ec1c24;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #ec1c24;
  border-right: 2px solid #ec1c24;
  border-left: 2px solid #ec1c24;
  cursor: pointer;
  &:hover {
    color: #ec1c24;
    background-color: white;
  }
`;

const FormDoc = (props) => {
  const { visForm, handleCloseForm, handleToggleLayer, handleType, documento } =
    props;
  const [form] = Form.useForm();

  const handleSend = () => {
    //  form.submit();
    //  handleToggleLayer();
    //  handleType(1);

    form
      .validateFields()
      .then((data) => {
        form.resetFields();
        handleSendMail(data);
        // handleToggleLayer();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleSendMail = async (data) => {
    data["docid"] = documento.id;
    try {
      //setLoading(true);
      const response = await Service.sendMail(data);
      //console.log(response);
      if (response.data.status === 200) {
        handleType(1);
      } else {
        handleType(2);
      }
      handleToggleLayer();
    } catch (error) {
      //handleLogError(error);
      //setLoading(false);
    }
    //setLoading(false);
  };

  return (
    <>
      <CustomBox visibility={visForm}>
        <Row gutter={24}>
          <Col offset={23}>
            <CloseCircleOutlined
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={handleCloseForm}
            />
          </Col>
        </Row>
        <Form name="form-invia-doc" form={form} layout="vertical">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Nome" name="nome">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Cognome" name="cognome">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Telefono" name="telefono">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Per favore inserisci l'email!" },
                  {
                    type: "email",
                    message: "Formato email errato!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Richiesta" name="richiesta">
                <TextArea
                  showCount
                  maxLength={225}
                  rows={5}
                  placeholder="Scrivi il tuo messaggio qui"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomBox>
      <CustomBoxBtn visibility={visForm} onClick={handleSend}>
        INVIA RICHIESTA
      </CustomBoxBtn>
    </>
  );
};

export default FormDoc;
