import React from "react";
import styled from "styled-components";
import { Box, Stack } from "./styled";
import { Row, Col} from "antd";


const CustomBox = styled(Box)`
  width: 80%;
  height: 100%;
  border: 0px solid red;
  margin: 0 auto;
  margin-top: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const CustomTitle = styled("span")`
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin: 5px;
`;

const CustomValue = styled("span")`
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: black;
  margin: 5px;
`;
const SchedaDoc = (props) => {
  const { metadati } = props;
  var metaModello = metadati.metadati;
  return (
    <CustomBox>
      <Stack
        direction="column"
        align="start"
        spacing="30px"
        flex="1 1 auto"
        border="0px solid"
        style={{ marginTop: "5px", marginBottom: "5px" }}
      >
        <Row>
          <Col span={24}>
            <CustomTitle>NOME</CustomTitle>
            <br></br>
            <CustomValue>{metadati?.nomedocumento || "--"}</CustomValue>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomTitle>DESCRIZIONE</CustomTitle>
            <br></br>
            <CustomValue>{metadati?.descrizione || "--"}</CustomValue>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomTitle>CLASSIFICAZIONE</CustomTitle>
            <br></br>
            <CustomValue>{metadati?.classificazione || "--"}</CustomValue>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomTitle>MODELLO</CustomTitle>
            <br></br>
            <CustomValue>{metadati?.modello || "--"}</CustomValue>
          </Col>
        </Row>
        <Row style={{ border: "0px solid" }}>
          <Col span={24}>
            <span style={{ fontWeight: 700, color: "black" }}>METADATI</span>
          </Col>
        </Row>
        <Box
          style={{
            borderBottom: "2px solid black",
            marginTop: "2px",
            width: "100%",
          }}
        ></Box>

        {metaModello ? Object.keys(metaModello).map((key) => {
          return SchedaMeta(key, metaModello[key]);
        }): ""}
      </Stack>
    </CustomBox>
  );
};

export default SchedaDoc;

function SchedaMeta(nomeGruppo, metadati) {
  return (
    <Box
      style={{
        borderBottom: "0px solid black",
        marginTop: "10px",
        width: "100%",
        margin: "5px",
      }}
    >
      <Row style={{marginTop:'10px',marginBottom:'10px' }}>
        <Col span={24}>
          <span
            style={{ fontFamily: 'Roboto Condensed', fontWeight: 600, fontSize: "14px"}}
          >
            {nomeGruppo.toUpperCase()}
          </span>
          <br></br>
        </Col>
      </Row>
      {Object.keys(metadati).map((key) => {
        return (
          <>
            <Row gutter={[16, 48]} style={{ border: "0px solid" }}>
              <Col
                span={6}
                style={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "black",
                }}
              >
                {capitalizeFirstLetter(key)}:
              </Col>
              <Col
                span={18}
                style={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "black",
                }}
              >
                {metadati[key]}
              </Col>
            </Row>
            <br></br>
          </>
        );
      })}
    </Box>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
