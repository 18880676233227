import React from "react";
import styled from "styled-components";
import { Tabs, TabList, Tab, TabPanel} from "react-tabs";
import { Box, Stack } from "./styled";
import { Pagination, Empty,Spin} from "antd";
import "../css/risultatoRicerca.css";
import ItemRisultato from "./ItemRisultato";

const CustomBoxItems = styled(Box)`
width: 80%;
border: 0px solid;
margin: 0px auto;
margin-top: 10px;
margin-bottom: 50px;
`;

const CustomBoxScheda = styled(Box)``;

const STabs = styled(Tabs)`
  font-family: "Roboto";
  font-size: 12px;
  color: black;
  width: 100%;
`;

const STabList = styled(TabList)`
  list-style-type: none;
  padding: 4px;
  padding-left: 0px;
  display: flex;
  margin: 0;
`;
STabList.tabsRole = "TabList";

const STab = styled(Tab)`
  margin-right: 4px;
  border: 2px solid black;
  border-bottom: 0px;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  user-select: none;
  cursor: pointer;

  &.is-selected {
    color: white;
    background: black;
    border-bottom: 1px solid black;
  }

  &:focus {
    outline: none;
    //box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
  }
`;
STab.tabsRole = "Tab";

const STabPanel = styled(TabPanel)`
  display: none;
  min-height: 40vh;
  border: 2px solid black;
  padding: 4px;
  margin-top: -5px;
  background: white;

  &.is-selected {
    display: block;
  }
`;
STabPanel.tabsRole = "TabPanel";
const RisultatiRicerca = (props) => {
  const { risultato,page,total,handlePage,handleGoToItem,loading_ricerca } = props;
  const onChange = (pageNumber) => {
    handlePage(pageNumber)
  };
  return (

    <CustomBoxScheda>
      <section id='ref-risultati'></section>
      <STabs
        selectedTabClassName="is-selected"
        selectedTabPanelClassName="is-selected"
        selectedIndex={0}
      >
        <STabList>
          <STab key={1}>SCHEDA DOCUMENTO</STab>
        </STabList>
        <STabPanel>
        <Spin size="large"  spinning={loading_ricerca} style={{marginTop:'10%'}}>
        <CustomBoxItems>
       
       { total>0 ? risultato?.map((item,index)=>
 
          <ItemRisultato item={item} handleGoToItem={handleGoToItem}/>
       ): <Empty description={
        <span style={{fontFamily:'Roboto',color:'#565550'}}>
          Nessun risultato trovato!
        </span>
      }/>} 
        </CustomBoxItems>
        </Spin>
      
        </STabPanel>
        <div style={{ position: "relative" }}>
        {total>0 ?  <Pagination
            style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              border: "0px solid",
              marginBottom: "10px",
              marginTop:'10px',
              textAlign: "center",
              width: "100%",
            }}
            defaultCurrent={page}
            total={total}
            showSizeChanger={false}
            onChange={onChange}
          /> :""}
        </div>
      </STabs>
      <Box height={["5px", "15px"]} width="100%"></Box>
    </CustomBoxScheda>
    
  );
};

export default RisultatiRicerca;
