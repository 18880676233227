import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { BiLinkExternal } from "react-icons/bi";
import Parser from 'html-react-parser';
const Titolo = styled.p`
  font-size: 18px;
  margin-bottom: 16px;
  /* Link text M active */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  cursor: pointer;
  /* or 19px */
  text-decoration-line: underline;
  /* nero */
  color: #161712;
  &:hover {
    color: #EC1C24;
    //box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
  }
`;

const Riassunto = styled.p`
  /* Body S condensed */
  font-family: "Roboto Condensed";
  font-style: normal;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* grigioscuro */
  color: #565550;
`;

const RiassuntoLabel = styled.label`
  /* Body S condensed */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* grigioscuro */
  color: #565550;
`;

const BiLinkExternalParaf = styled.p`
  font-size: 25px;
  color: "black";
  cursor: pointer;
  &:hover {
    color: #EC1C24;
    //box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
  }
`;

const ItemRisultato = (props) => {
  const { item,handleGoToItem } = props;
  const hanldeGo = () =>{
    handleGoToItem(item.contenitoreid,item.id);
  }
  return (
    <>
      <Row style={{ border: "0px solid" }}>
        <Col span={2} style={{ border: "0px solid", textAlign: "center" }}>
          {item.metadati.tipo === "File" ? (
            <BsFileEarmarkTextFill style={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
        </Col>
        <Col span={20} style={{ border: "0px solid", textAlign: "left" }}>
          <Titolo onClick={hanldeGo}>{item.descrizione}</Titolo>
        </Col>
        <Col span={2} style={{ border: "0px solid", textAlign: "center" }}>
          <BiLinkExternalParaf onClick={hanldeGo}><BiLinkExternal  style={{ fontSize: "25px" }}/></BiLinkExternalParaf>
        </Col>
      </Row>
      <Row style={{ border: "0px solid" }}>
        <Col
          span={2}
          style={{ border: "0px solid", textAlign: "center" }}
        ></Col>
        <Col span={20} style={{ border: "0px solid", textAlign: "left" }}>
          <Riassunto>
            <RiassuntoLabel>da: </RiassuntoLabel>
            {headline(item.headline)}
            <RiassuntoLabel> - Autore: </RiassuntoLabel>
            {item.metadati.autore}
            <RiassuntoLabel> - Data inserimento: </RiassuntoLabel>
            {item.metadati.datainserimento}
            <RiassuntoLabel> - Ultima modifica: </RiassuntoLabel>
            {item.metadati.ultimamodifica}
            <RiassuntoLabel> - Modello: </RiassuntoLabel>
            {item.metadati.modello}
          </Riassunto>
          
        </Col>
        <Col
          span={2}
          style={{ border: "0px solid", textAlign: "center" }}
        ></Col>
      </Row>
    </>
  );
};



const headline = (txt) => {
  var riassunto = txt;
  riassunto = replaceAll('<b>', '<span style="color:#EC1C24">',riassunto);
  riassunto = replaceAll('</b>', '</span>',riassunto);
  return(
    <>
   {Parser(riassunto)}
   </>
  )
}

function replaceAll(find, replace, str) {
  if(str == null) return "";
  if(str === undefined) return "";
  if (typeof(str) == "number") return str;
  if (typeof str === 'string' || str instanceof String) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
  else {
    return str;
  }
}




export default ItemRisultato;
