import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormRicercaFull from "../components/FormRicercaFull";
import Layout from "../components/Layout";
import { Container, Stack } from "../components/styled";
import styled from "styled-components";
import { Box } from "../components/styled";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { fetchMetadati } from "../redux/reducers/meta-reducer";
import { fetchRicerca, setPage } from "../redux/reducers/ricerca-reducer";
import RisultatiRicerca from "../components/RisultatiRicerca";
import { getItemFromLocalStorage } from "../redux/api";
import moment from "moment";
import { Result } from "antd";

const CustomBox = styled(Box)`
  padding: 5px;
  margin-top: 0px;
  border: 0px solid;
  margin: 0 auto;
  width: 70%;
  height: 100%;
`;

const RicercaFull = () => {
  const navigate = useNavigate();
  const [affinaVis, setAffinaVis] = useState(false);
  const [formCercaVis, setFormCercaVis] = useState(true);
  const [dataSearch, setDataSearch] = useState([]);
  // set up dispatch
  const dispatch = useDispatch();
  // fetch data dal nostro store redux
  // destrutturiamo lo slice appDetail
  const { metadati, loading_meta } = useSelector((state) => state.metadati);

  const { risultato, loading_ricerca, page, total } = useSelector(
    (state) => state.ricerca
  );

  const { id } = useParams();
  useEffect(() => {
    if (id !== "-1") {
      dispatch(fetchMetadati(id));
    } else {
      if (getItemFromLocalStorage("query_search")) {
        var data = {};
        data["q"] = getItemFromLocalStorage("query_search");
        setDataSearch(data);
        //console.log(data);
        dispatch(fetchRicerca(data, page));
      }
    }
  }, [dispatch, id, getItemFromLocalStorage("query_search")]);

  useEffect(() => {
    risultato?.length > 0 ? setAffinaVis(true) : setAffinaVis(false);
    // eslint-disable-next-line no-unused-expressions
    //risultato?.length > 0 ? window.scrollTo() : "";
  }, [risultato]);

  const handleRicerca = (data) => {
    //console.log(data);
    for (const key in data) {
      //console.log(`${key} -> ${data[key]}`)
      if (key.includes("data")) {
        var d = data[key];
        if (d != undefined && d.length != 0) {
          let tmpdata = moment(d[0]);
          data[key] = tmpdata.utc().format("DD-MM-YYYY");
        }
      }
    }
    data["modello"] = id;
    if (getItemFromLocalStorage("query_search")) {
      data["q"] = getItemFromLocalStorage("query_search");
    }
    setDataSearch(data);
    dispatch(fetchRicerca(data, page));
  };

  const handlePage = (page) => {
    dispatch(setPage(page));
    dispatch(fetchRicerca(dataSearch, page));
  };

  const toggleRicerca = () => {
    setFormCercaVis(!formCercaVis);
  };

  const handleGoToItem = (contenitoreid,docid) => {
    if(contenitoreid === docid){
      navigate("/archivio/" + contenitoreid);
    }else{
      navigate("/archivio/" + contenitoreid + "/" + docid);
    }
  };
  return (
    <Layout>
      <Container
        size="xl"
        style={{ border: "0px solid" }}
        mt={["24px", "50px"]}
      >
        <Stack
          direction="column"
          align="start"
          spacing="48px"
          flex="1 1 auto"
          border="0px solid"
          style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <CustomBox>
            {total != null ? (
              <RisultatiRicerca
                loading_ricerca={loading_ricerca}
                handleGoToItem={handleGoToItem}
                handlePage={handlePage}
                page={page}
                total={total}
                risultato={risultato}
              />
            ) : (
             ""
            )}
            {id !== "-1" && id !== ""  && id !== undefined ? (
              <FormRicercaFull
                metadati={metadati}
                affinaVis={affinaVis}
                loading_meta={loading_meta}
                formCercaVis={formCercaVis}
                toggleRicerca={toggleRicerca}
                handleRicerca={handleRicerca}
              />
            ) : (
              ""
            )}
            {
              total === null &&  !getItemFromLocalStorage("query_search") ?  <Result
              status="404"
              title="404"
              subTitle="Ci scusiamo. Nessuna risultato trovato!"
            />: ""
            }
          </CustomBox>
        </Stack>
      </Container>
    </Layout>
  );
};

export default RicercaFull;
