const theme = {
    breakpoints: ["40em", "80em"],//quando vogliamo che le media query sia attive
    color: {
      white: "#fff",
      black: "#161712",
      giallo: "#E7B25E",
      rosso: "#E83E2D",
      rossovecchio: "#6B2E26",
      rossoscambiato: "#D66859",
      rossogiovane: "#0D99FF",
      grigiochiaro:"#D1C7BB",
      grigiomedio:"#818386",
      grigiorosso:"#9A7D74",
      grigioscuro:"565550",
      error: "#ff6966",
      purple: {
        300: "#f31caa",
        600: "#ad3eff",
      },
      grey: {

        900: "#0e0e0e",
        800: "#121212",
        700: "#6c6767",
        600: "#898989",
        500: "#B9B9B9",
      },
    },
    background: {
      black: "#161712",
      giallo: "#E7B25E",
      rosso: "#E83E2D",
      rossovecchio: "#6B2E26",
      rossoscambiato: "#D66859",
      rossogiovane: "#EC1C24",
      grigiochiaro:"#D1C7BB",
      grigiomedio:"#818386",
      grigiorosso:"#9A7D74",
      grigioscuro:"#565550",
      error: "#ff6966",
      red:{
        200: "#E7E2DC",
        300: "#E7B25E",//hover link home
      },
      purple: {
        300: "#f31caa",
        600: "#ad3eff",
      },
      grey: {
        900: "#0e0e0e",
        800: "#121212",
        750: "#565550",//footer
        700: "#6c6767",
        600: "#898989",
        500: "#B9B9B9",
      },
    },
    backgroundColor:{
      black: "#161712",
      giallo: "#E7B25E",
      rosso: "#E83E2D",
      rossovecchio: "#6B2E26",
      rossoscambiato: "#D66859",
      rossogiovane: "#0D99FF",
      grigiochiaro:"#D1C7BB",
      grigiomedio:"#818386",
      grigiorosso:"#9A7D74",
      grigioscuro:"565550",
      error: "#ff6966",
    },
    fontFamily: "Roboto"
  };
  
  export default theme;