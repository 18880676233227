import React, { useEffect, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Divider, Row, Col, Input, Select, Form } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Box } from "./styled";
import "../css/ricerca.css";
// redux mapping
import { useDispatch } from "react-redux";
import {resetRicerca} from "../redux/reducers/ricerca-reducer";
import {
  getItemFromLocalStorage,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../redux/api";

const CustomBoxRicerca = styled(Box)`
  padding: 10px;
  width: 510px;
  justify-content:end;
`;

const Ricerca = (props) => {
    // set up dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lkmodelli} = props;

  const [visibilityRicerca, setVisibilityRicerca] = useState(getItemFromLocalStorage("query_search")  ? true : false);
  const [marginTopRicerca, setMarginTopRicerca] = useState(getItemFromLocalStorage("query_search") ? "0px" : "50px");
  const [visibilityRicercaAv, setVisibilityRicercaAv] = useState(getItemFromLocalStorage("contenitore")  || getItemFromLocalStorage("modello")  ? true : false);
  const [marginTopRicercaAv, setMarginTopRicercaAv] = useState(getItemFromLocalStorage("contenitore")  || getItemFromLocalStorage("modello") ? "0px" : "50px");

  const handleShowRicerca = () => {
    let mt = visibilityRicerca ? "50px" : "0px";
    setMarginTopRicerca(mt);
    setVisibilityRicerca(!visibilityRicerca);
  };

  const handleShowRicercaAv = () => {
    let mt = visibilityRicercaAv ? "50px" : "0px";
    setMarginTopRicercaAv(mt);
    setVisibilityRicercaAv(!visibilityRicercaAv);
  };
  const [form] = Form.useForm();

  const handleChangeModello = (modelloid) => {
    if(modelloid === undefined) return;
    dispatch(resetRicerca());
    setLocalStorageItem("modello", modelloid);
    navigate("/ricerca/" + modelloid);
  };

  const handleSearch = (value) => {
   // var modello = getItemFromLocalStorage("modello") ? getItemFromLocalStorage("modello") : "-1";
    var modello ="-1";
    navigate("/ricerca/"+modello );
  }
 



  const handleChangeQuery = (e) => {
    if (e.target.value === "") {
      removeLocalStorageItem("query_search");
    } else {
      setLocalStorageItem("query_search", e.target.value);
    }
    setLocalStorageItem("query_search", e.target.value);
  };

  // const handleChangeContenitore = (contenitoreid) => {
  //   setLocalStorageItem("contenitore", contenitoreid);
  // };

  // const handleClearContenitore = () => {
  //   removeLocalStorageItem("contenitore");
  // };

  const handleClearModello = () => {
    removeLocalStorageItem("modello");
  };

  const setFormData = () => {
    form.setFieldsValue({
        contenitore:getItemFromLocalStorage("contenitore"),
        modello:getItemFromLocalStorage("modello"),
        query:getItemFromLocalStorage("query_search")
    });
  };

  useEffect(() => {
    setFormData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomBoxRicerca style={{ border: "0px solid red" }}>
      <Form name="form-ricerca" form={form}>
        <Row gutter={24} justify="center">
          <Col
            span={9}
            style={{ marginTop: marginTopRicerca, border: "0px solid red" }}
          >
            <Row gutter={24} style={{ border: "0px solid red" }} justify="center">
              <Col span={19} style={{ border: "0px solid red" }}>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  Ricerca Libera
                </p>
              </Col>
              <Col span={4} style={{ border: "0px solid red" }}>
                {visibilityRicerca ? (
                  <UpOutlined onClick={handleShowRicerca} />
                ) : (
                  <DownOutlined onClick={handleShowRicerca} />
                )}
              </Col>
            </Row>
            <Row gutter={24} justify="center">
              <Col span={24}>
                <Divider
                  style={{
                    borderBlockStart: "1px solid black",
                    margin: "2px 0",
                  }}
                />
              </Col>
            </Row>
            <Row
              gutter={24}
              justify="center"
              style={{ display: visibilityRicerca ? "" : "none" } }
            >
              <Col span={24}>
              <Form.Item name="query">
                <Input.Search
                  allowClear
                  style={{
                    width: "100%",
                    marginTop: "10px",
                  }}
                  defaultValue=""
                  onChange={handleChangeQuery}
                  onSearch={handleSearch}
                />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col
            span={10}
            style={{ marginTop: marginTopRicercaAv, border: "0px solid red" }}
          >
            <Row gutter={24} justify="center">
              <Col span={21}>
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    textTransform: "uppercase",
                  }}
                >
                  Ricerca Avanzata
                </span>
              </Col>
              <Col span={2}>
                {visibilityRicercaAv ? (
                  <UpOutlined onClick={handleShowRicercaAv} />
                ) : (
                  <DownOutlined onClick={handleShowRicercaAv} />
                )}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Divider
                  style={{
                    borderBlockStart: "1px solid black",
                    margin: "2px 0",
                  }}
                />
              </Col>
            </Row>
            <Row
              gutter={24}
              style={{ display: visibilityRicercaAv ? "" : "none" }}
            >
              <Col span={12}>
              <Form.Item name="modello">
                <Select
                  onChange={handleChangeModello}
                  placeholder="Scegli modello"
                  style={{ width: "190px", marginTop: "10px" }}
                  allowClear
                  onClear={handleClearModello}
                >
                  {lkmodelli}
                </Select>
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="contenitore">
                  <Select
                    style={{ width: "180px", marginTop: "10px" }}
                    allowClear
                    placeholder="Scegli contenitore"
                    onChange={handleChangeContenitore}
                    onClear={handleClearContenitore}
                  >
                    {lkcontenitori}
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomBoxRicerca>
  );
};

export default Ricerca;
