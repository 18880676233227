import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Container } from "../components/styled";
import "../css/archivio.css";
import BoxOverlayer from "../components/BoxOverlayer";
import { useParams } from "react-router-dom";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import ArchivioFrame from "../components/ArchivioFrame";
import {
  fetchTree,
  fetchTreeById,
  setNode2,
  setNode3,
  setNode4,
  setNode5,
  setNode6,
  setFile,
  setDepth,
  setArrDepth,
  setLoading,
} from "../redux/reducers/tree_reducer";
import { useNavigate } from "react-router-dom";
import { fetchDocumento, fetchMetadati } from "../redux/reducers/doc-reducer";
import { FloatButton } from "antd";
const Archivio = () => {
  const navigate = useNavigate();

  const { id, docid } = useParams();
  // set up dispatch
  const dispatch = useDispatch();
  // fetch data dal nostro store redux
  // destrutturiamo lo slice appDetail
  const {
    loading,
    loading1,
    loading2,
    loading3,
    loading4,
    loading5,
    loading6,
    livello1,
    node2,
    livello2,
    livello3,
    livello4,
    livello5,
    livello6,
    node3,
    node4,
    node5,
    node6,
    arr_depth,
    depth,
  } = useSelector((state) => state.tree);

  const { loading_doc, metadati, documento } = useSelector(
    (state) => state.document
  );
  const { loading_ricerca } = useSelector((state) => state.ricerca);
  const [typeOverLayer, setTypeOverLayer] = useState(1);
  const [boxOverLayerVisibility, setBoxOverLayerVisibility] = useState(false);
  /*gestisce la visibilità della scheda+ preview el documento*/
  const [schedaVisibility, setSchedaVisibility] = useState(false);

  const handleToggleLayer = () => {
    setBoxOverLayerVisibility(!boxOverLayerVisibility);
  };

  const handleType = (type) => {
    setTypeOverLayer(type);
  };

  //handle alla selezione di un nodo dell'albero
  const handleSelectNode = (node, depth) => {
    dispatch(setDepth(depth));
    if (depth === "2") {
      setSchedaVisibility(false);
      dispatch(setNode3(null));
      dispatch(setNode4(null));
      dispatch(setNode5(null));
      dispatch(setNode6(null));
      dispatch(setNode2(node[0]));
      //  dispatch(setArrDepth({}));
    } else {
      setSchedaVisibility(false);
      dispatch(setNode4(null));
      dispatch(setNode5(null));
      dispatch(setNode6(null));
      dispatch(setNode3(node[0]));
    }
    navigate("/archivio/" + node, { replace: true });
    //console.log("selected", selectedKeys, info);
  };
  //handle all'expand di un nodo dell'albero
  const handleExpandNode = (node) => {
    setSchedaVisibility(false);
    dispatch(setDepth(2));
    dispatch(setNode2(node[0]));
  };

  //handle al click di una folder dell'archivo

  const handleSelectFolderArchvio = (node) => {
    setSchedaVisibility(false);
    if (parseInt(depth) === 2) {
      dispatch(setDepth(3));
      dispatch(setNode6(null));
      dispatch(setNode5(null));
      dispatch(setNode4(null));
      dispatch(setNode3(node));
    } else if (parseInt(depth) === 3) {
      dispatch(setDepth(4));
      dispatch(setNode6(null));
      dispatch(setNode5(null));
      dispatch(setNode4(node));
    } else if (parseInt(depth) === 4) {
      dispatch(setDepth(5));
      dispatch(setNode6(null));
      dispatch(setNode5(node));
    } else {
      dispatch(setDepth(parseInt(depth) + 1));
      var element = {};
      element.depth = parseInt(depth) + 1;
      element.node = node4;

      //console.log(newItem);
      dispatch(setArrDepth(element));
      dispatch(setNode6(node4));
      dispatch(setNode4(node5));
      dispatch(setNode5(node));
    }
    navigate("/archivio/" + node, { replace: true });
  };

  const handleBackFolderNav = (node) => {
    setSchedaVisibility(false);
    if (parseInt(depth) === 5) {
      dispatch(setDepth(parseInt(depth) - 1));
      dispatch(setNode4(node));
      dispatch(setNode5(null));
      dispatch(setNode6(null));
    } else if (parseInt(depth) >= 6) {
      dispatch(setDepth(parseInt(depth - 1)));
      dispatch(setNode4(node6));
      dispatch(setNode5(node));
      if (depth === 6) {
        dispatch(setNode6(null));
      } else {
        let node = arr_depth?.filter((x) => x.depth === parseInt(depth - 1))[0]
          .node;
        dispatch(setNode6(node));
      }
    }
    navigate("/archivio/" + node, { replace: true });
  };

  const handleSelectFolderArchvioNav = (node) => {
    //setto il nodo 4
    if (parseInt(depth) === 4) {
      dispatch(setDepth(4));
      dispatch(setNode6(null));
      dispatch(setNode5(null));
      setSchedaVisibility(false);
      dispatch(setNode4(node));
    }
    //setto il nodo 5
    if (parseInt(depth) === 5) {
      dispatch(setDepth(5));
      dispatch(setNode6(null));
      setSchedaVisibility(false);
      dispatch(setNode5(node));
    }
    //setto il nodo 6
    if (parseInt(depth) >= 6) {
      //dispatch(setDepth(parseInt(depth)+1));
      dispatch(setDepth(parseInt(depth)));
      setSchedaVisibility(false);
      dispatch(setNode5(node));
    }
    navigate("/archivio/" + node, { replace: true });
  };

  const handleFile = (docid) => {
    navigate("/archivio/" + id + "/" + docid , {
      replace: true,
    });
    dispatch(setFile(docid));
    setSchedaVisibility(true);
    dispatch(fetchMetadati(docid));
    dispatch(fetchDocumento(docid));
  };

  useEffect(() => {
    if (
      loading1 === false &&
      loading2 === false &&
      loading3 === false &&
      loading4 === false &&
      loading5 === false &&
      loading6 === false
    ) {
      dispatch(setLoading(false));
    }
  }, [dispatch, loading1, loading2, loading3, loading4, loading5, loading6]);

  useEffect(() => {
    if (livello1.length === 0) {
      dispatch(fetchTree(1, 2));
    }
  }, [dispatch, livello1.length]);

  useEffect(() => {
    if (node2 != null) {
      dispatch(fetchTree(node2, 2, 2));
    }
  }, [dispatch, node2]);

  useEffect(() => {
    if (node3 != null) {
      dispatch(fetchTree(node3, 2, 3));
    }
  }, [dispatch, node3]);

  useEffect(() => {
    if (node4 != null) {
      dispatch(fetchTree(node4, 2, 4));
    }
  }, [dispatch, node4]);

  useEffect(() => {
    if (node5 != null) {
      dispatch(fetchTree(node5, 2, 5));
    }
  }, [dispatch, node5]);

  useEffect(() => {
    if (node6 != null) {
      dispatch(fetchTree(node6, 2, 6));
    }
  }, [dispatch, node6]);

  useEffect(() => {
    if (id != null) {
      dispatch(fetchTreeById(id));
    }
    if (docid != null) {
      handleFile(docid);
    }
  }, [dispatch, id, docid]);

  return (
    <Layout>
      <BoxOverlayer
        type={typeOverLayer}
        boxOverLayerVisibility={boxOverLayerVisibility}
        handleToggleLayer={handleToggleLayer}
      ></BoxOverlayer>
      <Container
        size="xl"
        style={{ border: "0px solid" }}
        mt={["24px", "50px"]}
      >
        <ArchivioFrame
          livello1={livello1.data}
          livello2={livello2.data}
          livello3={livello3.data}
          livello4={livello4.data}
          livello5={livello5.data}
          livello6={livello6.data}
          defaultExpandKeys={node2}
          node3={node3}
          node4={node4}
          node5={node5}
          node6={node6}
          handleSelectFolderArchvio={handleSelectFolderArchvio}
          handleSelectFolderArchvioNav={handleSelectFolderArchvioNav}
          handleBackFolderNav={handleBackFolderNav}
          handleSelectNode={handleSelectNode}
          handleExpandNode={handleExpandNode}
          handleToggleLayer={handleToggleLayer}
          handleType={handleType}
          schedaVisibility={schedaVisibility}
          handleFile={handleFile}
          loading={loading}
          loading_doc={loading_doc}
          loading_ricerca={loading_ricerca}
          metadati={metadati}
          documento={documento}
          depth={depth}
          docidurl={docid}
        />
      </Container>
      <FloatButton.BackTop />
    </Layout>
  );
};

export default Archivio;
