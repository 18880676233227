import Home from "./pages/Home";
import Archivio from "./pages/Archivio";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RicercaFull from "./pages/RicercaFull";
import NotFound from "./pages/NotFound";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/archivio/" element={<Archivio />} />
        <Route path="/archivio/:id" element={<Archivio />} />
        <Route path="/archivio/:id/:docid" element={<Archivio />} />
        <Route path="/ricerca/:id" element={<RicercaFull />} />
        <Route path="/ricerca/" element={<RicercaFull />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    
  );
}

export default App;
