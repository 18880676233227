import React from "react";
import { Stack } from "../components/styled";
import styled from "styled-components";
import { Box, Container } from "../components/styled";
import Folder from "./Folder";
import File from "./File";
import { Empty } from "antd";
import ContentTabsDoc from "./ContentTabsDoc";

const CustomBox = styled(Box)`
  padding: 5px;
  margin-top: 0px;
  border: 0px solid;
  margin: 0 auto;
  width: 80%;
  height: 100%;
`;

const CustomSpam = styled("span")`
  font-weight: 600;
  font-family: "Roboto";
  font-size: 32px;
  cursor: pointer;
  &:hover {
    color: #e7b25e;
  }
`;

const ArchivioLiv2 = (props) => {
  const {
    data,
    handleSelectFolderArchvio,
    node3,
    handleToggleLayer,
    handleType,
    handleFile,
    schedaVisibility,
    loading_doc,
    metadati,depth,documento,
    docidurl
  } = props;


  const onClickTitle = (key) => {
    handleSelectFolderArchvio(key);
  };

  const handleFolder = (tmpnode4, tmpnode3) => {
    parseInt(depth) === 2 ? handleSelectFolderArchvio(tmpnode3) : handleSelectFolderArchvio(tmpnode4)
  };

  const handleFileLiv2 = (id) => {
    handleFile(id)
  }

  return (
    <>
      <Stack
        direction="column"
        align="start"
        spacing="48px"
        flex="1 1 auto"
        border="0px solid"
        style={{ marginTop: "5px", marginBottom: "5px" }}
      >
        {node3 === null
          ? data?.map((item) =>
              item.tipo === "folder"
                ? singleItem(onClickTitle, handleFolder,handleFileLiv2, item,docidurl)
                : ""
            )
          : data
              ?.filter((item) => item.key === node3)
              .map((item) =>
                item.tipo === "folder"
                  ? singleItem(onClickTitle, handleFolder, handleFileLiv2,item,docidurl)
                  : ""
              )}
        <CustomBox>
          <Stack
            align="start"
            style={{ border: "0px solid", flexWrap: "wrap" }}
          >
            {node3 === null
              ? data?.map((item) =>
                  item.tipo === "file" ? (
                    <File
                      id={item.key}
                      depth={item.depth}
                      title={item.title}
                      docidurl={docidurl}
                      descrizione={item.descrizione2}
                      handleFile={() => handleFileLiv2(item.key)}
                    />
                  ) : (
                    ""
                  )
                )
              : data
                  ?.filter((item) => item.key === node3)
                  .map((item) =>
                    item.tipo === "file" ? (
                      <File
                        id={item.key}
                        docidurl={docidurl}
                        depth={item.depth}
                        title={item.title}
                        descrizione={item.descrizione2}
                        handleFile={() => handleFileLiv2(item.key)}
                      />
                    ) : (
                      ""
                    )
                  )}
          </Stack>
        </CustomBox>
        <CustomBox>
          <ContentTabsDoc
            handleToggleLayer={handleToggleLayer}
            handleType={handleType}
            visibilityScheda={schedaVisibility}
            metadati={metadati}
            documento={documento}
            loading_doc={loading_doc}
          />
        </CustomBox>
      </Stack>
    </>
  );
};

export default ArchivioLiv2;

function singleItem(onClickTitle, handleFolder, handleFile,item,docidurl) {
  //console.log(item);
  return (
    <CustomBox>
        <CustomSpam onClick={() => onClickTitle(item.key)}>
          {item.title}
        </CustomSpam>
      <Stack align="start" style={{ border: "0px solid", flexWrap: "wrap" }}>
        {item?.children?.length > 0 ? (
          item?.children?.map((x) =>
            x.tipo === "folder" ? (
              <Folder
                id={x.key}
                depth={x.depth}
                handleFolder={() => handleFolder(x.key, item.key)}
                title={x.title}
              />
            ) : (
              <File
                id={x.key}
                depth={x.depth}
                title={x.title}
                docidurl={docidurl}
                descrizione={x.descrizione2}
                handleFile={() => handleFile(x.key)}
              />
            )
          )
        ) : item?.children?.length === 0 && item?.children.tipo === "folder" ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          ""
        )}
      </Stack>
    </CustomBox>
  );
}
