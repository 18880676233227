import React from "react";
import { Stack } from "../components/styled";
import styled from "styled-components";
import { Box } from "../components/styled";
import Folder from "./Folder";
import File from "./File";
import { Empty } from "antd";
import ContentTabsDoc from "./ContentTabsDoc";

const CustomBox = styled(Box)`
  padding: 5px;
  margin-top: 0px;
  border: 0px solid;
  margin: 0 auto;
  width: 80%;
  height: 100%;
`;

const CustomSpam = styled("span")`
  font-weight: 600;
  font-family: "Roboto";
  font-size: 32px;
  cursor: pointer;
  &:hover {
    color: #e7b25e;
  }
`;

const ArchivioLiv3 = (props) => {
  const {
    livello3,
    livello4,
    node4,
    node5,
    handleSelectFolderArchvio,
    depth,
    handleFile,
    handleToggleLayer,
    handleType,
    schedaVisibility,
    loading_doc,
    metadati,documento,
    docidurl
  } = props;
  const handleFolder = (id) => {
    handleSelectFolderArchvio(id);
  };

  const onClickTitle = (key) => {
    handleSelectFolderArchvio(key);
  };

  const handleFileLiv2 = (id) => {
    handleFile(id);
  };
  //console.log(depth);
  return (
    <>
      <Stack
        direction="column"
        align="start"
        spacing="48px"
        flex="1 1 auto"
        border="0px solid"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        {/* {node4 === null
        ? livello3?.map((item) => singleItem(item))
        : livello3?.filter(item => item.key === node4).map((item) => singleItem(item,handleFolder))} 
        {node5 === null
          ? livello4?.map((item) => singleItem(item))
          : livello4
              ?.filter((item) => item.key === node5)
              .map((item) => singleItem(item, handleFolder))}  */}
        {/* {parseInt(depth) === 3
          ? livello3?.map((item) => singleItem(item))
          : parseInt(depth) === 4
          ? livello3
              ?.filter((item) => item.key === node4)
              .map((item) => singleItem(item, handleFolder))
          : parseInt(depth) === 5
          ? livello4?.map((item) => singleItem(item))
          : parseInt(depth) === 6
          ? livello4
              ?.filter((item) => item.key === node5)
              .map((item) => singleItem(item, handleFolder))
          : ""} */}

        {parseInt(depth) === 4 ? (
          livello3
            ?.filter((item) => item.key === node4)
            .map((item) =>
              item.tipo === "folder" ?
              singleItem(item, handleFolder, handleFileLiv2, onClickTitle,docidurl)
              :""
            )
        ) : parseInt(depth) >= 5 ? (
          livello4
            ?.filter((item) => item.key === node5)
            .map((item) =>
              item.tipo === "folder" ?
              singleItem(item, handleFolder, handleFileLiv2, onClickTitle,docidurl)
              : ""

            )
        ) : (
          <CustomBox>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </CustomBox>
        )}
         <CustomBox>
          
          <Stack
            align="start"
            style={{ border: "0px solid", flexWrap: "wrap" }}
          >
         {/* INSERIRE EVENTUALI FILE PRESENTI NELLA ROOT DELLA FOLDER VEDI ARCHIVIOLIV2 */}
          </Stack>
        </CustomBox>
        <CustomBox>
          <ContentTabsDoc
            handleToggleLayer={handleToggleLayer}
            handleType={handleType}
            visibilityScheda={schedaVisibility}
            metadati={metadati}
            documento={documento}
            loading_doc={loading_doc}
          />
        </CustomBox>
      </Stack>
    </>
  );
};

function singleItem(item, handleFolder, handleFile, onClickTitle,docidurl) {
  return (
    <CustomBox>
      <CustomSpam onClick={() => onClickTitle(item.key)}>
        {item.title}
      </CustomSpam>
      <Stack align="start" style={{ border: "0px solid", flexWrap: "wrap" }}>
        {item?.children?.length > 0 ? (
          item?.children?.map((x) =>
            x.tipo === "folder" ? (
              <Folder
                id={x.key}
                depth={x.depth}
                handleFolder={() => handleFolder(x.key, item.key)}
                title={x.title}
              />
            ) : (
              <File
                id={x.key}
                docidurl={docidurl}
                depth={x.depth}
                title={x.title}
                descrizione={x.descrizione2}
                handleFile={() => handleFile(x.key)}
              />
            )
          )
        ) 
        // : item?.children?.length === 0 && item?.children.tipo === "folder" ? (
        //   <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        // ) 
        : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Stack>
    </CustomBox>
  );
}

/*
function singleItem(item, handleFolder) {
  return (
    <CustomBox>
      <CustomSpam>{item.title}</CustomSpam>
      <Stack align="start" style={{ border: "0px solid", flexWrap: "wrap" }}>
        {item?.children?.length > 0 ? (item?.children?.map((x) => (

          <Folder
            id={x.key}
            depth={x.depth}
            title={x.title} 
            handleFolder={() => handleFolder(x.key)}
          />
        ))) : item?.children?.length === 0 && item?.children.tipo === "folder" ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <CustomBox><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></CustomBox>
        )}
      </Stack>
    </CustomBox>
  );
}
*/
export default ArchivioLiv3;
