import React, { useEffect, useState } from "react";
import { Col, Row, Tree, Spin, Empty } from "antd";
import ArchivioLiv2 from "./ArchivioLiv2";
import ArchivioLiv3 from "./ArchivioLiv3";
import FolderNav from "./FolderNav";

const ArchivioFrame = (props) => {
  const {
    depth,
    handleToggleLayer,
    handleType,
    schedaVisibility,
    defaultExpandKeys,
    handleBackFolderNav,
    handleSelectFolderArchvio,
    handleSelectFolderArchvioNav,
    handleSelectNode,
    handleExpandNode,
    handleFile,
    livello1,
    livello2,
    livello3,
    livello4,
    livello6,
    node3,
    node4,
    node5,
    node6,
    loading,
    loading_doc,
    metadati,
    documento,
    docidurl,
  } = props;

  const [defaultTreeNodeOpen, setDefaultTreeNodeOpen] = useState([
    defaultExpandKeys,
  ]);

  const [selectedTreeNode, setSelectedTreeNode] = useState([node3]);

  const onSelect = (selectedKeys, { node }) => {
    var { expandedKeys } = defaultTreeNodeOpen;
    if (node.depth === "2") {
      expandedKeys = [node.key];
      setDefaultTreeNodeOpen([node.key]);
      setSelectedTreeNode([node.key]);
    } else {
      expandedKeys = [node.key];
      setSelectedTreeNode([node.key]);
    }
    handleSelectNode(expandedKeys, node.depth);
  };

  const onExpand = (keys, info) => {
    var { expandedKeys } = defaultTreeNodeOpen;
    expandedKeys = [keys[1]];
    setDefaultTreeNodeOpen([keys[1]]);
    handleExpandNode(expandedKeys);
  };

  useEffect(() => {
    setSelectedTreeNode([node3]);
  }, [node3]);

  useEffect(() => {
    setDefaultTreeNodeOpen([defaultExpandKeys]);
  }, [defaultExpandKeys]);

  return (
    <>
      <Spin size="large" spinning={loading}>
        <Row>
          <Col
            span={4}
            style={{
              minHeight: "600px",
              borderRight: "1px solid",
              borderLeft: "1px solid",
              paddingTop: "20px",
            }}
          >
            <Tree
              showLine
              treeData={livello1}
              expandedKeys={defaultTreeNodeOpen}
              selectedKeys={selectedTreeNode}
              onSelect={onSelect}
              onExpand={onExpand}
            />
          </Col>
          {parseInt(depth) === 2 || parseInt(depth) === 3 ? (
            <Col
              span={20}
              style={{
                backgroundColor: "#F9F7F6",
                border: "0px solid green",
              }}
            >
              <ArchivioLiv2
                data={livello2}
                node3={node3}
                handleSelectFolderArchvio={handleSelectFolderArchvio}
                handleToggleLayer={handleToggleLayer}
                handleType={handleType}
                schedaVisibility={schedaVisibility}
                handleFile={handleFile}
                loading_doc={loading_doc}
                metadati={metadati}
                documento={documento}
                depth={depth}
                docidurl={docidurl}
              />
            </Col>
          ) : parseInt(depth) >= 4 ? (
            <>
              <Col span={4} style={{ borderRight: "1px solid" }}>
                <FolderNav
                  handleFolderNav={handleSelectFolderArchvioNav}
                  handleBackFolderNav={handleBackFolderNav}
                  node4={node4}
                  node3={node3}
                  node5={node5}
                  node6={node6}
                  depth={depth}
                  livello2={livello2}
                  livello3={livello3}
                  livello6={livello6}
                />
              </Col>
              <Col span={16} style={{ backgroundColor: "#F9F7F6" }}>
                <ArchivioLiv3
                  livello3={livello3}
                  livello4={livello4}
                  node4={node4}
                  node5={node5}
                  depth={depth}
                  handleFile={handleFile}
                  handleSelectFolderArchvio={handleSelectFolderArchvio}
                  handleToggleLayer={handleToggleLayer}
                  handleType={handleType}
                  schedaVisibility={schedaVisibility}
                  loading_doc={loading_doc}
                  metadati={metadati}
                  documento={documento}
                  docidurl={docidurl}
                />
              </Col>
            </>
          ) : (
            <Col span={20}>
              {" "}
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          )}
        </Row>
      </Spin>
    </>
  );
};
/*
condition1 ? value1
        : condition2 ? value2
        : condition3 ? value3
        : value4;

*/
export default ArchivioFrame;
