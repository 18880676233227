import React from "react";
import { BsFileTextFill } from "react-icons/bs";
import styled from "styled-components";
import { Box } from "./styled";
import { Row, Col, Anchor } from "antd";
import { display } from "styled-system";
import AnchorLink from "react-anchor-link-smooth-scroll-v2";

// import { Anchor } from "antd";
const { Link } = Anchor;

const CustomBox = styled(Box)`
  width: 280px;
  height: 100%;
  margin: 20px;
  margin-left: 0px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 600;
  border: 0px solid red;
  cursor: pointer;
  &:hover {
    color: #ec1c24;
    font-weight: 600;
  }
`;

const File = (props) => {
  const { title, descrizione, id, depth, handleFile, docidurl } = props;
  let colorFile = docidurl === id ? "red" : "black";
  const onClickFile = () => {
    handleFile(id);
  };

  const style = {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "18px",
    color: "black",
  };
  return (
    // <AnchorLink offset="300" href="#ref-schedadoc" style={style}>
      <CustomBox key={id} depth={depth} onClick={onClickFile}>
        {/* <Anchor>
        <Link href="#scheda-documento" title="scheda-documento"> */}
        {/* <span style={{ display: "inline-flex", alignItems: "center" }}>
            <BsFileTextFill style={{ fontSize: "18px", marginRight: "5px" }} />
            {descrizione === null ? title.substring(0, 20) + '...' : descrizione}
          </span> */}
        {/* </Link>
      </Anchor> */}

        <Row
          style={{
            border: "0px solid green",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <Col span={2}>
            <BsFileTextFill
              style={{
                fontSize: "18px",
                marginRight: "5px",
                color: `${colorFile}`,
              }}
            />
          </Col>
          <Col
            span={22}
            style={{
              fontFamily: "Roboto",
              fontWeight: 600,
              fontSize: "16px",
              color: `${colorFile}`,
            }}
          >
            {descrizione === null || descrizione === ""
              ? title.length > 20
                ? title.substring(0, 20) + "..."
                : title
              : descrizione}
          </Col>
        </Row>
      </CustomBox>
    // </AnchorLink>
  );
};

export default File;
