import React, { useEffect, useState } from "react";
import { Container, Stack, Box } from "./styled";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logoHeader.svg";
import styled from "styled-components";
import Ricerca from "./Ricerca";
import {Select} from "antd";
// redux mapping
import { useDispatch, useSelector } from "react-redux";

import {
  fetchModelli
} from "../redux/reducers/modelli-reducer";

const{Option}=Select;

const Parag = styled.p({
  color: "#EC1C24",
  fontFamily: "Roboto",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "26.4px",
});

const Header = () => {
   const [lkmodelli, setLkmodelli] = useState([]);
   const [lkcontenitori, setLkcontenitori] = useState([]);
   // set up dispatch
   const dispatch = useDispatch();
   // fetch data dal nostro store redux
   // destrutturiamo lo slice appDetail
   const { modelli,contenitori } = useSelector(
     (state) => state.modelli
   );

  const setLkModelli = () =>{
    //console.log(modelli);
    /*carico la lookup dei modelli*/
    const lk_modelli = [];
    modelli.map((modello) => {
     // console.log(modello);
      lk_modelli.push(<Option value={modello.id} key={modello.id}>{modello.nome.trim()}</Option>);
      return lk_modelli;
    });
    setLkmodelli(lk_modelli)
  }
  
  const setLkContenitori = () =>{
    //console.log(modelli);
    /*carico la lookup dei modelli*/
    const lk_contenitori= [];
    contenitori.map((contenitori) => {
     // console.log(modello);
     lk_contenitori.push(<Option value={contenitori.id} key={contenitori.id}>{contenitori.descrizione.trim()}</Option>);
      return lk_contenitori;
    });
    setLkcontenitori(lk_contenitori)
  } 
  // useEffect(() => {
  //   setLkContenitori();
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [contenitori])

  useEffect(() => {
    setLkModelli();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelli])

  useEffect(() => {
    dispatch(fetchModelli());
    //dispatch(fetchContenitori());
  }, [dispatch])

  return (
    <>
      <Container
        size="fullwidth"
        position="fixed"
        background="red.200"
        borderBottom="1px solid"
        zIndex={999}
        height={["100px", "200px"]}
      >
        <Container size="xl" style={{ border: '0px solid red' }} >
          <Stack width="100%" justify="flex-start">
            <Stack
             // spacing="150px"
              spacing="80px"
              align="center"
              justify="flex-start"
              width="100%"
              style={{ border: '0px solid red' }}
              height={["100px", "200px"]} //corrisponde alle media query il primo valore mobile
            >
              <Box>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Logo height="105px" />
                </Link>
              </Box>
              <Box>
                <Parag>
                  Benvenuti nell’Archivio storico dello Spi-Cgil Nazionale<br></br>
                  <p
                    style={{
                      color: "black",
                      fontWeight: "400",
                      fontSize: "16px",
                      fontFamily: "Roboto Condensed",
                      lineHeight: "19px",
                    }}
                  >
                    <span style={{fontStyle:'italic'}}>"Il portale che conserva documenti, foto, audio e video del Sindacato Pensionati della CGIL."</span>
                  </p>
                </Parag>
              </Box>
              <Ricerca lkmodelli={lkmodelli} lkcontenitori={lkcontenitori}></Ricerca>

            </Stack>
          </Stack>
        </Container>
      </Container>
      <Box height={["64px", "150px"]} width="100%"></Box>
    </>
  );
};

export default Header;
