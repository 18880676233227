import {
    space,
    layout,
    typography,
    color,
    border,
    shadow,
    position,
    compose,
    variant,
    borderColor,
    fontFamily,
    lineHeight,
    letterSpacing,
  } from "styled-system";
  import { css } from "@styled-system/css";
  import styled from "styled-components";


  const StyledButton = styled("button")(
    variant({
      prop: "size",
      variants: {
        sm: {
          height: "24px",
          paddingX: "10px",
          fontSize: "12px",
          borderLeft:'5px solid',
          borderColor:"#E7B25E"
        },
        md: {
          height: "36px",
          paddingX: "14px",
          fontSize: "14px",
          borderLeft:'6px solid',
          borderColor:"#E7B25E"
        },
        lg: {
          height: "48px",
          paddingX: "14px",
          fontSize: "28px",
          borderLeft:'8px solid',
          borderColor:"#E7B25E"
        },
        xl: {
          height: "64px",
          paddingX: "22px",
          fontSize: "32px",
          fontWeight: 800,
          borderLeft:'14px solid',
          borderColor:"#E7B25E",
          fontFamily:"Roboto",
          lineHeight:"35px",
          letterSpacing:"0px"
        },
      },
    }),
    variant({
      variants: {
        text: {
          background: "transparent",
          color: "black",
          border: "none",
          "&:hover": {
            background:"giallo"
          },
        },
        disabled: {
          background: "grey.600",
          color: "grey.800",
          border: "none",
          cursor: "not-allowed",
        },
      },
    }),
    ({ isFullwidth, iconColor }) =>
      css({
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: isFullwidth ? "100%" : undefined,
        transition: "all 250ms, transform 0.1s ease-in-out",
        fontWeight: "600",
        outline: "none",
        whiteSpace: "nowrap",
        userSelect: "none",
        cursor: "pointer",
        letterSpacing: "0.1rem",
        "& svg > *": {
          fill: iconColor ? iconColor : "white",
          transition: "all 250ms, transform 0.1s ease-in-out",
        },
      }),
    compose(space, typography, color, position, shadow, border, layout)
  );
  
  const StyledContent = styled("div")({
    transition: "all 125ms",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    border:'0px solid'
  });
  
  const ButtonIcon = styled.span(
    {
      display: "inline-flex",
      alignSelf: "center",
      fontSize: "16px",
      flexShrink: 0,
      color: "inherit",
      border:'0px solid'
    },
    space
  );
  
  const LinkHome = ({
    variant,
    leftIcon,
    rightIcon,
    children,
    isLoading = false,
    ...rest
  }) => {
    return (
      <StyledButton
        disabled={isLoading}
        variant={isLoading ? "disabled" : variant || "contained"}
        {...rest}
      >
        <StyledContent>
          {!isLoading && leftIcon && (
            <ButtonIcon mr='6px'> {leftIcon} </ButtonIcon>
          )}
  
          {children}
  
          {!isLoading && rightIcon && (
            <ButtonIcon ml='6px'> {rightIcon} </ButtonIcon>
          )}
        </StyledContent>
      </StyledButton>
    );
  };
  
  export default LinkHome;