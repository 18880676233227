import { createSlice } from "@reduxjs/toolkit";
import { api2 } from "../api";

// initial state
export const initialState = {
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  loading4: false,
  loading5: false,
  loading6: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  percorso: [],
  livello1: [],
  livello2: [],
  livello3: [],
  livello4: [],
  livello5: [],
  livello6: [],
  node2: null,
  node3: null,
  node4: null,
  node5: null,
  node6: null,
  file: null,
  depth: 1,
  arr_depth: [],
};

// our slice
const treeSlice = createSlice({
  name: "tree",
  initialState,
  reducers: {
    setLoading: (state,{ payload }) => {
      state.loading = payload;
    },
    setLoading1: (state,{ payload }) => {
      state.loading1 = payload;
    },
    setLoading2: (state,{ payload }) => {
      state.loading2 = payload;
    },
    setLoading3: (state,{ payload }) => {
      state.loading3 = payload;
    },
    setLoading4: (state,{ payload }) => {
      state.loading4 = payload;
    },
    setLoading5: (state,{ payload }) => {
      state.loading5 = payload;
    },
    setLoading6: (state,{ payload }) => {
      state.loading6 = payload;
    },
    setPercorso: (state, { payload }) => {
      //state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.percorso = payload;
    },

    setLivello1: (state, { payload }) => {
      state.loading1 = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.livello1 = payload;
    },
    setLivello2: (state, { payload }) => {
      state.loading2 = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.livello2 = payload;
    },
    setLivello3: (state, { payload }) => {
      state.loading3 = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.livello3 = payload;
    },
    setLivello4: (state, { payload }) => {
      state.loading4 = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.livello4 = payload;
    },
    setLivello5: (state, { payload }) => {
      state.loading5 = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.livello5 = payload;
    },
    setLivello6: (state, { payload }) => {
      state.loading6 = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.livello6 = payload;
    },
    setFile: (state, { payload }) => {
      state.file = payload;
    },
    setArrDepth: (state, { payload }) => {
      state.arr_depth = state.arr_depth.filter(
        (el) => el.depth !== payload.depth
      );
      state.arr_depth = [...state.arr_depth, payload];
    },
    deleteItemArrDepth: (state, { payload }) => {
      state.arr_depth = state.arr_depth.content.filter(
        (el) => el.depth !== payload
      );
    },
    setDepth: (state, { payload }) => {
      state.depth = payload;
    },
    setNode2: (state, { payload }) => {
      state.node2 = payload;
    },
    setNode3: (state, { payload }) => {
      state.node3 = payload;
    },
    setNode4: (state, { payload }) => {
      state.node4 = payload;
    },
    setNode5: (state, { payload }) => {
      state.node5 = payload;
    },
    setNode6: (state, { payload }) => {
      state.node6 = payload;
    },
    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
  },
});

// export the actions
export const {
  cleanError,
  setLoading,
  setError,
  setPercorso,
  setLoading1,
  setLoading2,
  setLoading3,
  setLoading4,
  setLoading5,
  setLoading6,
  setLivello1,
  setLivello2,
  setLivello3,
  setLivello4,
  setLivello5,
  setLivello6,
  setArrDepth,
  deleteItemArrDepth,
  setDepth,
  setNode2,
  setNode3,
  setNode4,
  setNode5,
  setNode6,
  setFile,
} = treeSlice.actions;

// export the default reducer
export default treeSlice.reducer;

export const fetchTreeById = (id) => async (dispatch) => {
  dispatch(cleanError());
 // dispatch(setLoading(true));
  let path = "api.php?f=percorso&id=" + id;
  try {
    const response = await api2.get(path, {
      headers: {
        "Content-type": "application/json",
      },
    });
    dispatch(setPercorso(response.data));
    dispatch(setDepth(parseInt(response.data[0].length + 1)));

    if (response.data[0].length >= 5) {
      var first = response.data[0][0].id;
      var second = response.data[0][1].id;
      var lastId = response.data[0][response.data[0].length - 1].id;
      var lastId2 = response.data[0][response.data[0].length - 2].id;
      var lastId3 = response.data[0][response.data[0].length - 3].id;
      var lastId4 = response.data[0][response.data[0].length - 4].id;
      var lastId5 = response.data[0][response.data[0].length - 5].id;
      dispatch(setNode2(first));
      dispatch(setNode3(second));
      dispatch(setNode4(lastId2));
      dispatch(setNode5(lastId));
      dispatch(setNode6(lastId3));
      var depth = response.data[0].length + 1;
      var element = {};
      element.depth = depth;
      element.node = lastId3;
      dispatch(setArrDepth(element));
      var element2 = {};
      element2.depth = depth - 1;
      element2.node = lastId4;
      dispatch(setArrDepth(element2));
      var element3 = {};
      element3.depth = depth - 2;
      element3.node = lastId5;
      dispatch(setArrDepth(element3));
    } else {
      dispatch(setNode2(response.data[0][0].id));
      dispatch(setNode3(response.data[0][1].id));
      if(response.data[0].length >2)
        dispatch(setNode4(response.data[0][2].id));
      if(response.data[0].length >3)  
        dispatch(setNode5(response.data[0][3].id));
    }
  } catch (err) {
    dispatch(setError(err.response));
  }
};
export const fetchTree =
  (node, depth, level = 1) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    if(level === 1){
      dispatch(setLoading1(true));
    }else if (level === 2) {
      dispatch(setLoading2(true));
    } else if (level === 3) {
      dispatch(setLoading3(true));
    } else if (level === 4) {
      dispatch(setLoading4(true));
    } else if (level === 5) {
      dispatch(setLoading5(true));
    } else if (level === 6) {
      dispatch(setLoading6(true));
    }
    dispatch(cleanError());
    let path =
      "api.php?f=getTree&node=" + node + "&depth=" + depth + "&level=" + level;
    try {
      const response = await api2.get(path, {
        headers: {
          "Content-type": "application/json",
        },
      });
      if (level === 1) {
        dispatch(setLivello1(response.data));
      } else if (level === 2) {
        dispatch(setLivello2(response.data));
      } else if (level === 3) {
        dispatch(setLivello3(response.data));
      } else if (level === 4) {
        dispatch(setLivello4(response.data));
      } else if (level === 5) {
        dispatch(setLivello5(response.data));
      } else if (level === 6) {
        dispatch(setLivello6(response.data));
      }
    
    } catch (err) {
      dispatch(setError(err.response));
    }
  };
