import { configureStore } from "@reduxjs/toolkit";
import modelliReducer from "./reducers/modelli-reducer";
import tree_reducer from "./reducers/tree_reducer";
import document_reducer from "./reducers/doc-reducer";
import metadati_reducer from "./reducers/meta-reducer";
import ricerca_reducer from "./reducers/ricerca-reducer";

export const store = configureStore({
  reducer: {
    modelli: modelliReducer,
    tree: tree_reducer,
    document: document_reducer,
    metadati: metadati_reducer,
    ricerca: ricerca_reducer,
  },
});
