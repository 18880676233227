import React from "react";
import { Container, Stack, Box } from "./styled";
import { ReactComponent as Logo } from "../images/logoFooter.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Parag = styled.p({
  color: "white",
  fontFamily: "Roboto Condensed",
});

const Footer = () => {
  return (
  
    <Container
      size="fullwidth"
      mt={["24px", "0px"]}
      height={["100px", "143px"]}
      background="grigioscuro"
      pb="72px"
    >
      <Container size="xl" /*pl={["24px", "72px"]}*/>
        <Stack width="100%" justify="flex-start">
          <Stack align="center" spacing="180px" height={["100px", "143px"]}>
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                }}
              >
                <Logo height="105px" />
              </Link>
            </Box>
            <Parag>
              SEDE<br></br> Via dei Frentani, 4/A <br></br>00185 ROMA{" "}
            </Parag>
            <Parag>
              RESPONSABILE: Francesco Palaia <br></br>REFERENTE: Alessandra Barbosa <br></br>tel. fisso: 0644481318 <br></br>e-mail
              archivio@spi.cgil.it
            </Parag>
            <Parag>
              CODICE FISCALE<br></br> 80186730588
            </Parag>
          </Stack>
        </Stack>
      </Container>
    </Container>
  );
};

export default Footer;
